import React, { useContext, useRef } from "react";
import ThemeContext from "../../theme/ThemeContext";
import { Route, Routes } from "react-router-dom";
import "../../scss/style.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadScript } from "@react-google-maps/api";
import AppFooter from "../appFooter";
import AppHeader from "../appHeader";
import { PageLoader } from "./page-loader";
import { ProtectedRoute } from "./protected-route";
import "./AppStyles.scss";
import "@coreui/coreui-pro/dist/css/coreui.min.css";
import { CButton } from "@coreui/react";

const Vehicle = React.lazy(
  () => import("../routes/vehicle/data/InitialRequests")
);
const Map = React.lazy(() => import("../routes/map/Map"));
const Settings = React.lazy(() => import("../routes/settings/Settings"));

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS || "";

function App() {
  const { darkmode } = useContext(ThemeContext);
  const { isLoading, user, logout, isAuthenticated } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
  let email = user?.email;
  let email_domain = email?.split("@")[1];

  // Accepted Email logins
  if (
    email_domain !== "stealth-power.com" &&
    email_domain !== "velociti.com" &&
    email_domain !== "merlinsolar.com" &&
    email_domain !== "willburt.com" &&
    email_domain !== undefined
  ) {
    return (
      <>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <h3>You are not authorized</h3>
          You don't have permission to view this dashboard.
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <CButton
            color={darkmode ? "dark" : "secondary"}
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Return to login
          </CButton>
        </div>
      </>
    );
  }

  // If the user has not verified their email account
  if (!user?.email_verified && email_domain !== undefined) {
    return (
      <>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <h3>Please Verify your Email Account.</h3>
          An email was sent to your registered email account. Please check your
          email and verify your account by clicking the link, then try logging
          in again.
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <CButton
            className="back-to-login-btn"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Return to login
          </CButton>
        </div>
      </>
    );
  }
  const main = useRef(null);

  return (
    <div className={darkmode ? "main-style-dark o2n-app" : "main-style-light"}>
      <AppHeader />
      <React.Suspense>
        <div className="body">
          <main
            data-testid="main"
            ref={main}
            style={{
              display: "flex",
              flexGrow: 1,
              minHeight: "100%",
            }}
          >
            <div className="container-fluid px-sm-0">
              <Routes>
                <Route
                  path="/"
                  element={<ProtectedRoute component={Vehicle} />}
                />
                <Route
                  path="/map"
                  element={<ProtectedRoute component={Map} />}
                />
                <Route
                  path="/vehicle"
                  element={<ProtectedRoute component={Vehicle} />}
                />
                <Route
                  path="/settings"
                  element={<ProtectedRoute component={Settings} />}
                />
              </Routes>
            </div>
          </main>
        </div>
      </React.Suspense>
      <div>
        <AppFooter />
      </div>
    </div>
  );
}

export default App;
