export const SNAPSHOT_METRICS_LOADING = "SNAPSHOT_METRICS_LOADING";
export const SNAPSHOT_METRICS_SUCCESS = "SNAPSHOT_METRICS_SUCCESS";

export type SnapshotMetrics = {
  id: string;
  assetNumber: string;
  dates: string[];
  lastLoadCurrent: number | null;
  lastSystemTemp: number | null;
  lastDate: string;
  lastStateOfCharge: number;
  signalStrength: number | null;
  SPSystemStatus: number | string | null;
  stealthHours: number;
  lastSolarAmps: number | null;
  lastShoreline: number | null;
};

export interface SnapshotMetricsLoading {
  type: typeof SNAPSHOT_METRICS_LOADING;
}

export interface SnapshotMetricsSuccess {
  type: typeof SNAPSHOT_METRICS_SUCCESS;
  payload: Array<SnapshotMetrics>;
}

export type SnapshotMetricsDispatchTypes =
  | SnapshotMetricsLoading
  | SnapshotMetricsSuccess;
