export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  id: string;
  assetNumber: string;
  dates: string[];
  systemTemp: (number | null)[];
  batteryLoadCurrent: (number | null)[];
  lastLoadCurrent: number | null;
  lastSystemTemp: number | null;
  lastDate: string;
  lastStateOfCharge: number;
  stateOfCharge: (number | null)[];
  lastOEMVoltage: number | null;
  lastSolarAmps: number | null;
  OEMVoltage: (number | null)[];
  stealthVoltage: (number | null)[];
  solarAmps: (number | null)[];
  lastStealthVoltage: number | null;
  SPState: { state: string; date: string }[];
  stealthHours: number;
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
